import axios from '../config/api.config'

class PropertyService {
  getAll() {
    return axios.get('/properties')
  }

  get(id) {
    return axios.get(`/properties/${id}`)
  }

  update(id, data) {
    return axios.patch(`/properties/${id}`, data)
  }

  addPerson(id, data) {
    return axios.patch(`/properties/${id}/person/add`, data)
  }
  updatePerson(id, data) {
    return axios.patch(`/properties/${id}/person/update`, data)
  }
  deletePerson(id, data) {
    return axios.patch(`/properties/${id}/person/delete`, data)
  }

  addChild(id, data) {
    return axios.patch(`/properties/${id}/child/add`, data)
  }
  updateChild(id, data) {
    return axios.patch(`/properties/${id}/child/update`, data)
  }
  deleteChild(id, data) {
    return axios.patch(`/properties/${id}/child/delete`, data)
  }

  addPet(id, data) {
    return axios.patch(`/properties/${id}/pet/add`, data)
  }
  updatePet(id, data) {
    return axios.patch(`/properties/${id}/pet/update`, data)
  }
  deletePet(id, data) {
    return axios.patch(`/properties/${id}/pet/delete`, data)
  }

  addDue(id, data) {
    return axios.patch(`/properties/${id}/dues/add`, data)
  }
  updateDue(id, data) {
    return axios.patch(`/properties/${id}/dues/update`, data)
  }
  deleteDue(id, data) {
    return axios.patch(`/properties/${id}/dues/delete`, data)
  }
}

export default new PropertyService()
